/*-------- 4. Support style  -------*/

.support-wrap {
  display: flex;
  .support-icon {
    flex: 0 0 50px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .support-content {
    h5 {
      font-size: 24px;
      margin: 0 0 5px;
      color: $theme-color;
      text-align: start;
    }
    p {
      color: #666;
      text-align: start;
      margin: 0;
      text-align: 16px!important;
    }
  }
  &.support-2 {
    margin-left: 22px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 41px;
    }
  }
  &.support-3 {
    margin-left: 9px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 41px;
    }
  }
  &.support-4 {
    margin-left: 23px;
    @media #{$lg-layout} {
      margin-left: 0px;
    }
    @media #{$md-layout} {
      margin-left: 0px;
    }
    @media #{$xs-layout} {
      margin-left: 0px;
    }
    .support-icon {
      flex: 0 0 35px;
    }
  }
  &:hover .support-icon img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
}

/* support hm3 */
.support-wrap-2 {
  .support-content-2 {
    h5 {
      font-size: 18px;
      color: #494949;
      margin: 15px 0 15px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      color: #6a6a6a;
      margin: 0 auto;
      width: 80%;
      @media #{$md-layout} {
        width: 100%;
      }
    }
  }
  &:hover .support-content-2 img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
  &.support-shape {
    position: relative;
    &:before {
      position: absolute;
      right: -14px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #d5d4d4;
      height: 61px;
      width: 1px;
      content: "";

      @media #{$xs-layout} {
        display: none;
      }
    }
  }
  &.support-shape-2 {
    border: 4px solid #fff;
  }
  &.support-shape-3 {
    border: 4px solid #f6f6f6;
  }
  &.support-padding-2 {
    padding: 36px 10px 30px;
    @media #{$lg-layout} {
      padding: 36px 5px 30px;
    }
    @media #{$xs-layout} {
      padding: 36px 6px 30px;
    }
  }
}

.support-bg-color-1 {
  background-color: #ccfbe9;
}

.support-bg-color-2 {
  background-color: #f2fbcc;
}

.support-bg-color-3 {
  background-color: #ddfbcc;
}

.support-wrap-3 {
  position: relative;
  padding: 30px 10px 24px;
  overflow: hidden;
  .support-icon-2 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .support-content-3 {
    img {
    }
    p {
      color: #000000;
      margin: 4px 0 0;
      font-size: 16px;
      text-transform: uppercase;
      @media #{$xs-layout} {
        font-size: 15px;
      }
      @media #{$sm-layout} {
        font-size: 14px;
      }
    }
  }
  &:hover .support-icon-2 img {
    animation: 500ms ease-in-out 0s normal none 1 running tada;
  }
}

@media #{$xs-layout} {
  .support-area {
    &.pt-80 {
      padding-top: 30px;
    }
  }
}

.feature-icon-two-wrap {
  .col-md-4 {
    &:last-child {
      .support-wrap-2 {
        &.support-shape {
          &:last-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
